import { Box, Card, CardContent, CardHeader, CircularProgress } from "@mui/material"
import { DataGridPro } from "@mui/x-data-grid-pro"
import { useEffect, useState } from "react";
import ResponsiveColumns from "./ResponsiveColumns";
import { API } from "../../../lib/api";
import BaseWrapper from "../../../components/BaseWrapper/BaseWrapper";

const ForecastDataGrid = () => {
    const [loading, setLoading] = useState(true);
    const [forecastData,setForecastData] = useState([])
    const forecastColumns = ResponsiveColumns()

    useEffect(() => {
        async function FetchData() {
            const responseForecast = await API.Reporting.FetchForecastFile()
            if (responseForecast.status === 200) {
                setForecastData(responseForecast.data)
            }
            setLoading(false)
        }
        FetchData()
    }, [])

    return (
        <BaseWrapper content={
            <>
                {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                    <CircularProgress />
                </Box>
            ) : (
                
                <div className="col-xl-12 col-md-12" style={{marginTop: "1em", marginBottom: "1em"}}>
                    <Card>
                        <CardHeader title="Forecast File" />
                        <CardContent>
                            <DataGridPro
                            sx={{backgroundColor: "white"}}
                                checkboxSelection
                                rows={forecastData}
                                columns={forecastColumns}
                                getRowId={(row) => row["Revenue Row ID"]}
                                pagination
                                initialState={{
                                    pagination: {
                                        paginationModel: { pageSize: 100, page: 0 },
                                    },
                                }}
                                pageSizeOptions={[100, 250, 1000]}
                            />
                        </CardContent>
                    </Card>
                </div>
            )}  
            </>
        }/>    
    )
}

export default ForecastDataGrid