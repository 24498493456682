import { Box, CircularProgress } from "@mui/material"
import { useEffect, useState } from "react";
import { API } from "../../../lib/api";
import BaseWrapper from "../../../components/BaseWrapper/BaseWrapper";
import Bar from "../../../components/Reports/Bar";

const OppsByUser = () => {
    const [loading, setLoading] = useState(true);
    const [oppsByOwner, setOppsByOwner] = useState({})

    useEffect(() => {
        async function FetchData() {
            const response = await API.Reporting.FetchOppsByUser()
            if (response.status === 200) {
                setOppsByOwner(response.data)
            }
            setLoading(false)
        }
        FetchData()
    }, [])

    return (
        <BaseWrapper content={
            <>
                {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                    <CircularProgress />
                </Box>
            ) : (
                <Bar xData={oppsByOwner.xData} seriesData={oppsByOwner.seriesData} title={oppsByOwner.title} />
            )}  
            </>
        }/>    
    )
}

export default OppsByUser