import { useMediaQuery } from '@mui/material';

const ResponsiveColumns = () => {
    // Define the breakpoints
    const isMobile = useMediaQuery('(max-width:600px)');

    return [
        {
            field: "Opportunity ID",
            headerName: "Opportunity ID",
            flex: 1,
        },
        // Conditionally show/hide columns
        ...(isMobile ? [] : [
            {
                field: "Revenue Row ID",
                headerName: "Revenue Row ID",
                flex: 1,
            },
            {
                field: "Name",
                headerName: "Name",
                flex: 1,
            },
            {
                field: "Product/Source",
                headerName: "Product/Source",
                flex: 1,
            },
            {
                field: "Sales Stage Name",
                headerName: "Sales Stage Name",
                flex: 1,
            },
            {
                field: "Booked Orders Stage",
                headerName: "Booked Orders Stage",
                flex: 1,
            },
            {
                field: "Year",
                headerName: "Year",
                flex: 1,
            },
            {
                field: "Ship Date",
                headerName: "Ship Date",
                flex: 1,
            },
            {
                field: "Project Manager (PM)",
                headerName: "Project Manager (PM)",
                flex: 1,
            },
            {
                field: "Salesperson/Owner",
                headerName: "Salesperson/Owner",
                flex: 1,
            },
            {
                field: "Customer",
                headerName: "Customer",
                flex: 1,
            },
            {
                field: "Project Number",
                headerName: "Project Number",
                flex: 1,
            },
            {
                field: "Shipped To State",
                headerName: "Shipped To State",
                flex: 1,
            },
            {
                field: "Line Comments",
                headerName: "Line Comments",
                flex: 1,
            },
            {
                field: "Opportunity Line Revenue",
                headerName: "Opportunity Line Revenue",
                flex: 1,
            },
            {
                field: "Line Win Probability / 100",
                headerName: "Line Win Probability",
                flex: 1,
            },
        ]),
        {
            field: "Quote #",
            headerName: "Quote #",
            flex: 1,
        },
    ];
};

export default ResponsiveColumns