import { Box, Card, CardContent, CardHeader, CircularProgress } from "@mui/material"
import { DataGridPro } from "@mui/x-data-grid-pro"
import { useEffect, useState } from "react";
import { API } from "../../../lib/api";
import BaseWrapper from "../../../components/BaseWrapper/BaseWrapper";
import ResponsiveColumnsTSTM from "./ResponsiveColumnsTSTM";

const RevLinesTSTM = () => {
    const [loading, setLoading] = useState(true);
    const [revLinesTSTM, setRevLinesTSTM] = useState([]) 
    const tstmColumns = ResponsiveColumnsTSTM()

    useEffect(() => {
        async function FetchData() {
            const responseRevLinesTSTM = await API.Reporting.RevLinesTSTM()
            if (responseRevLinesTSTM.status === 200) {
                setRevLinesTSTM(responseRevLinesTSTM.data)
            }
            setLoading(false)
        }
        FetchData()
    }, [])

    return (
        <BaseWrapper content={
            <>
                {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                    <CircularProgress />
                </Box>
            ) : (
                
                <div className="col-xl-12 col-md-12" style={{marginTop: "1em", marginBottom: "1em"}}>
                    <Card>
                        <CardHeader title="Revenue Lines To Ship This Month" />
                        <CardContent>
                            <DataGridPro
                            sx={{backgroundColor: "white"}}
                                checkboxSelection
                                rows={revLinesTSTM}
                                columns={tstmColumns}
                                getRowId={(row) => row._id}
                                pagination
                                initialState={{
                                    pagination: {
                                        paginationModel: { pageSize: 10, page: 0 },
                                    },
                                }}
                                pageSizeOptions={[10, 25, 50]}
                            />
                        </CardContent>
                    </Card>
                </div> 
            )}  
            </>
        }/>    
    )
}

export default RevLinesTSTM                