import ContactsIcon from '@mui/icons-material/Contacts';
import { API } from '../../lib/api'
import * as XLSX from 'xlsx';
import UpdateIcon from '@mui/icons-material/Update';
import Feedback from '../../components/Feedback/Feedback'
import { useState } from 'react'
import Papa from 'papaparse';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';
import Module from './Module'
import { useNavigate } from 'react-router-dom'

const Content = () => {
    const [feedback, setFeedback] = useState({open: false, message: "", type: ""})
    
    const navigate = useNavigate();
    
    const authorizedRoles = ["admin", "manager"]

    const handleCustomerMaster = async () => {
        const response = await API.Reporting.FetchCustomerMaster(localStorage.token)

        if (response.status === 200) {
            const today = new Date();
            const formattedDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
            const worksheet = XLSX.utils.json_to_sheet(response.data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
            XLSX.writeFile(workbook, `Customer_Master_${formattedDate}.xlsx`);
        } else {
            setFeedback(prevData => ({ ...prevData, open: true, message: "You do not have permission to access this resource!", type: 'error' }))
        }
    };

    const handleForecastFile = async () => {
        const response = await API.Reporting.FetchForecastFile(localStorage.token);
    
        if (response.status === 200) {
            const today = new Date();
            const formattedDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
    
            // Convert JSON to CSV using PapaParse
            const csv = Papa.unparse(response.data, {
                quotes: true, // Ensures values are properly quoted
                quoteChar: '"',
                escapeChar: '"',
                delimiter: ",",
                header: true, // Ensures headers are included
            });
    
            // Create a Blob and trigger download
            const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = `The_Hive_Forecast_${formattedDate}.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            setFeedback(prevData => ({
                ...prevData,
                open: true,
                message: "You do not have permission to access this resource!",
                type: 'error',
            }));
        }
    };

    const openForecastDataGrid = () => {
        const { role } = localStorage
        if (!authorizedRoles.includes(role)) {
            setFeedback({open: true, message: "Unauthorized: You do not have permission to access this!", type: "error"})
            return
        }        
        navigate("/modules/forecast-data-grid");
    }
    const openRevLinesTSTM = () => {
        const { role } = localStorage
        if (!authorizedRoles.includes(role)) {
            setFeedback({open: true, message: "Unauthorized: You do not have permission to access this!", type: "error"})
            return
        }        
        navigate("/modules/revlines-tstm")
    }
    const openOppsByOwner = () => {
        const { role } = localStorage
        if (!authorizedRoles.includes(role)) {
            setFeedback({open: true, message: "Unauthorized: You do not have permission to access this!", type: "error"})
            return
        }        
        navigate("/modules/opps-by-owner")
    }

    const modules = [
        { title: "Forecast File Download", description: "Download Forecast File", onClick: handleForecastFile, icon: <UpdateIcon color='primary' sx={{fontSize: 50}} /> },
        { title: "Customer Master Download", description: "Download Customer Master", onClick: handleCustomerMaster, icon: <ContactsIcon color='primary' sx={{fontSize: 50}} /> },
        { title: "Forecast Data Grid", description: "Open Module", onClick: openForecastDataGrid, icon: <OnlinePredictionIcon color='primary' sx={{fontSize: 50}} /> },
        { title: "Revenue Lines To Ship This Month (Grid)", description: "Open Module", onClick: openRevLinesTSTM, icon: <OnlinePredictionIcon color='primary' sx={{fontSize: 50}} /> },
        { title: "Opportunities By Owner (Graph)", description: "Open Module", onClick: openOppsByOwner, icon: <OnlinePredictionIcon color='primary' sx={{fontSize: 50}} /> },
    ]

    return (
        <>
            <Feedback props={feedback} setFeedback={setFeedback} />
            <div className='row' style={{justifyContent: "center"}}>
                {modules.map(mod => (
                    <Module key={mod.title} title={mod.title} icon={mod.icon} description={mod.description} onClick={mod.onClick} />
                ))}
            </div>
        </>
    )
}

export default Content